import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const BackofficeUsers = Loadable(lazy(() => import('views/backoffice/users')));
const BackofficeTenantUsers = Loadable(lazy(() => import('views/backoffice/tenantUsers')));
const BackofficeTenants = Loadable(lazy(() => import('views/backoffice/tenants')));
const BackofficeTenantTypes = Loadable(lazy(() => import('views/backoffice/tenantTypes')));
const BackofficeRoles = Loadable(lazy(() => import('views/backoffice/roles')));
const BackofficeRecordTypes = Loadable(lazy(() => import('views/backoffice/recordTypes')));
const BackofficeRoleAliases = Loadable(lazy(() => import('views/backoffice/roleAliases')));
const BackofficeRecordsByTenantType = Loadable(lazy(() => import('views/backoffice/recordsByTenantType')));
const BackofficeRolesByTenantType = Loadable(lazy(() => import('views/backoffice/rolesByTenantType')));
const BackofficeRecordsAditionalFields = Loadable(lazy(() => import('views/backoffice/recordsAditionalFields')));
const BackofficeCoverageZones = Loadable(lazy(() => import('views/backoffice/coverageZones')));
const BackofficeAppModule = Loadable(lazy(() => import('views/backoffice/appModules')));
const BackofficeConfigurationByRole = Loadable(lazy(() => import('views/backoffice/configurationByRole')));
const BackofficeRoleActions = Loadable(lazy(() => import('views/backoffice/roleActions')));
const BackofficeProblemCode = Loadable(lazy(() => import('views/backoffice/problemCode')));
const BackofficeRecordStatus = Loadable(lazy(() => import('views/backoffice/recordStatus')));
const BackofficeTenantConfig = Loadable(lazy(() => import('views/backoffice/tenantConfig')));
const BackofficeRecordDetails = Loadable(lazy(() => import('views/backoffice/recordDetails')));
const BackofficeTransferSettings = Loadable(lazy(() => import('views/backoffice/transferSettings')));
const BackofficeCustomList = Loadable(lazy(() => import('views/backoffice/CustomLists')));
const BackofficeCustomObjects = Loadable(lazy(() => import('views/backoffice/CustomObjects')));
const BackofficeDashboardManagement = Loadable(lazy(() => import('views/backoffice/DashboardManagement')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardPage />
        },
        {
            path: '/backoffice/users',
            element: <BackofficeUsers />
        },
        {
            path: '/backoffice/tenants',
            element: <BackofficeTenants />
        },
        {
            path: '/backoffice/tenant-users',
            element: <BackofficeTenantUsers />
        },
        {
            path: '/backoffice/tenant-types',
            element: <BackofficeTenantTypes />
        },
        {
            path: '/backoffice/roles',
            element: <BackofficeRoles />
        },
        {
            path: '/backoffice/role-aliases',
            element: <BackofficeRoleAliases />
        },
        {
            path: '/backoffice/record-types',
            element: <BackofficeRecordTypes />
        },
        {
            path: '/backoffice/record-by-tenant-types',
            element: <BackofficeRecordsByTenantType />
        },
        {
            path: '/backoffice/roles-by-tenant-types',
            element: <BackofficeRolesByTenantType />
        },
        {
            path: '/backoffice/record-aditional-fields',
            element: <BackofficeRecordsAditionalFields />
        },
        {
            path: '/backoffice/coverage-zones',
            element: <BackofficeCoverageZones />
        },
        {
            path: '/backoffice/app-modules',
            element: <BackofficeAppModule />
        },
        {
            path: '/backoffice/configuration-by-role',
            element: <BackofficeConfigurationByRole />
        },
        {
            path: '/backoffice/role-actions',
            element: <BackofficeRoleActions />
        },
        {
            path: '/backoffice/problem-code',
            element: <BackofficeProblemCode />
        },
        {
            path: '/backoffice/record-status',
            element: <BackofficeRecordStatus />
        },
        {
            path: '/backoffice/tenant-config',
            element: <BackofficeTenantConfig />
        },
        {
            path: '/backoffice/record-details',
            element: <BackofficeRecordDetails />
        },
        {
            path: '/backoffice/transfer-settings',
            element: <BackofficeTransferSettings />
        },
        {
            path: '/backoffice/custom-lists',
            element: <BackofficeCustomList />
        },
        {
            path: '/backoffice/custom-objects',
            element: <BackofficeCustomObjects />
        },
        {
            path: '/backoffice/dashboard-management',
            element: <BackofficeDashboardManagement />
        }
    ]
};

export default MainRoutes;
